import { sortBy } from 'lodash'
import accountsApi from '@/services/api/accounts'
import suppliersApi from '@/services/api/suppliers'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  cost_center_options: [],
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  sort: {
    value: { name: 'name_asc', attr: 'suppliers.name' },
    options: [
      { name: 'name_asc', attr: 'suppliers.name' },
      { name: 'name_desc', attr: '-suppliers.name' },
    ],
  },
  filters: {
    accounts: {
      value: [],
      loading: false,
      selected: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
  },
  selected_supplier: {},
  list: [],
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_SUPPLIERS_DATA(state, { suppliers, ...paginationMeta }) {
    state.list = [...state.list, ...suppliers]
    state.current_page += 1
    state.total_count = paginationMeta.total_count
  },
  APPLY_FILTER(state, { type, filterItem }) {
    if (
      filterItem.id &&
      state.filters[type].value.some((itm) => itm.id === filterItem.id)
    ) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem.id !== filterItem.id
        }
      )
    } else {
      state.filters[type].value.push(filterItem)
    }
  },
  RESET_LOADED_SUPPLIERS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  RESET_SEARCH(state) {
    state.q = null
  },
  SELECT_SUPPLIER(state, supplier) {
    state.selected_supplier = supplier
  },
  SET_FILTER_LOADING(state, { value, type }) {
    state.filters[type].loading = value
  },
  SET_FILTER_OPTIONS(state, { data, type }) {
    state.filters[type].options = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code,
        type,
      }
    })
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
}

export const actions = {
  async addSupplier(_ctx, { id, ...params }) {
    await suppliersApi.update(id, params)
  },
  applyFilter({ commit }, { filterItem, type }) {
    commit('APPLY_FILTER', { filterItem, type })
    commit('RESET_LOADED_SUPPLIERS')
  },
  filterSuppliers({ commit }) {
    commit('RESET_LOADED_SUPPLIERS')
  },
  async loadAvailableSuppliers({ commit, state }) {
    commit('RESET_LOADED_SUPPLIERS')
    const response = await suppliersApi.index({
      q: state.q,
      all: true,
      only_discarded: true,
    })

    commit('ADD_SUPPLIERS_DATA', response.data)
  },
  async loadSuppliers({ commit, state }, infiniteLoaderContext) {
    const {
      current_page,
      q,
      filters: { accounts },
      sort: { value },
    } = state

    try {
      const response = await suppliersApi.index({
        page: current_page + 1,
        q,
        tenant_ids: accounts.value.map((item) => item.id),
        sort: value && value.attr,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_SUPPLIERS_DATA', response.data)
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  resetSearch({ commit }) {
    commit('RESET_SEARCH')
  },
  selectSupplier({ commit }, supplier) {
    commit('SELECT_SUPPLIER', supplier)
  },
  async loadFilterOptions({ commit }, type) {
    commit('SET_FILTER_LOADING', { value: true, type })

    let options = []

    switch (type) {
      case 'accounts': {
        const response = await accountsApi.shorts.index()
        options = response.data.accounts
        break
      }
    }

    commit('SET_FILTER_OPTIONS', {
      data: sortBy(options, (n) => n.name.toLowerCase(), 'name'),
      type,
    })

    commit('SET_FILTER_LOADING', { value: false, type })
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
}

export const getters = {
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
}
