<template>
  <div class="side-navigation-wrapper">
    <transition :name="isXsMobileView ? 'scale-modal' : 'fade'">
      <aside
        class="fixed overflow-y-auto overflow-x-hidden z-50 md:z-40 bg-alabaster flex flex-col h-screen transition-all duration-300 pb-6 border-r border-default-bg top-0"
        :class="{
          'w-21.7': isCollapsed,
          'w-full md:w-284px': !isCollapsed,
        }"
      >
        <div class="h-20 flex flex-none items-center justify-between px-4 py-3">
          <div class="md:hidden">
            <ButtonClose class="flex-none" @click="$emit('close-menu')" />
          </div>
          <div class="pl-2 xl:hidden">
            <MiniLogo
              v-if="isCollapsed"
              class="text-medium-gray cursor-pointer w-8"
              type="dark"
              @click="$router.push('/')"
            />
            <Logo
              v-else
              class="xl:flex-1 w-31 text-medium-gray cursor-pointer"
              type="dark"
              @click="$router.push('/')"
            />
          </div>
        </div>
        <div class="flex flex-col flex-1 justify-between pt-6">
          <!-- company & cost-center -->
          <div
            v-if="!isAdmin"
            class="px-4"
            @mouseover="handleCompanyHovered"
            @mouseleave="hoveredCompany = false"
            data-cy="company-cost-center"
            ref="companyMenu"
          >
            <div
              class="flex h-14 py-2 mb-6 flex-col bg-default-bg rounded-xl justify-center items-center relative"
              :class="{ 'w-14': isCollapsed, 'px-2': !isMobileView }"
              @mouseover="handleCompanyHovered"
            >
              <div class="absolute z-40 w-full h-full"></div>
              <div class="flex self-stretch justify-start items-center gap-3">
                <div
                  class="w-10 h-10 rounded-md justify-center items-center flex relative"
                >
                  <CompanyImageIcon
                    size="md"
                    :is-circle="false"
                    :data="currentUser.currentUser.company.value"
                    :class="{ 'px-2': isMobileView }"
                  />
                  <div
                    v-if="
                      isCollapsed && currentUser.currentUser.cost_center.value
                    "
                    class="absolute w-6 h-6 -bottom-2.5 -right-1 shadow-md"
                  >
                    <CompanyImageIcon
                      size="xxs"
                      :is-circle="false"
                      :data="currentUser.currentUser.cost_center.value"
                    />
                  </div>
                </div>
                <div :class="{ hidden: isCollapsed }">
                  <transition name="fade">
                    <div>
                      <div
                        class="truncate text-ellipsis text-xs text-s1-gray-100 font-medium"
                      >
                        {{ currentCompanyName || '-' }}
                      </div>
                      <div
                        class="truncate text-ellipsis text-xs text-s1-gray-80 font-normal"
                      >
                        {{ currentCostCenterName || '-' }}
                      </div>
                    </div>
                  </transition>
                </div>
                <transition :name="isMobileView ? 'scale-modal' : 'fade'">
                  <div
                    v-show="hoveredCompany"
                    class="bg-alabaster h-auto rounded-lg py-3 shadow-side-menu"
                    :class="{
                      'left-17.25': isCollapsed && !isMobileView,
                      'left-67': !isCollapsed && !isMobileView,
                      'w-64 top-0 px-3': !isMobileView,
                      'w-full top-16 z-60': isMobileView,
                    }"
                    @mouseover="handleCompanyHovered"
                    @mouseleave="hoveredCompany = false"
                    ref="companyDropdown"
                  >
                    <div v-if="isCompaniesModalOpened">
                      <div
                        class="flex justify-center items-center h-20 w-full"
                        v-if="areCompaniesLoading"
                      >
                        <Spinner size="12px" />
                      </div>
                      <div v-else>
                        <div v-for="company in companies" :key="company.id">
                          <div
                            class="grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-between items-center py-1.5 gap-4 flex w-full cursor-pointer"
                            @click="handleSelectCompany(company)"
                            @mouseover="currentCompanyHovered = company.id"
                            @mouseleave="currentCompanyHovered = null"
                            data-cy="company-cost-center-select-company"
                          >
                            <div class="flex flex-row items-center gap-2">
                              <CompanyImageIcon
                                size="sm"
                                :is-circle="false"
                                :data="company"
                              />
                              <span
                                class="truncate text-ellipsis"
                                :class="{
                                  'text-s1-blue-100':
                                    currentCompanyHovered === company.id,
                                }"
                                >{{ company.name }}</span
                              >
                            </div>
                            <ActionIcon
                              name="nav_navigate_next"
                              size="2xl"
                              :color="
                                currentCompanyHovered === company.id
                                  ? 's1-blue-100'
                                  : 's1-gray-80'
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        @click="handleCompaniesList"
                        class="grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-1.5 gap-4 flex w-full cursor-pointer"
                        @mouseover="currentCompanyHovered = selectedCompanyId"
                        @mouseleave="currentCompanyHovered = null"
                      >
                        <ActionIcon
                          name="nav_navigate_before"
                          size="2xl"
                          :color="
                            currentCompanyHovered ? 's1-blue-100' : 's1-gray-80'
                          "
                        />
                        <span
                          :class="{
                            'text-s1-blue-100':
                              currentCompanyHovered === selectedCompanyId,
                          }"
                          >{{
                            selectedCompanyId
                              ? currentUser.currentUser.company.value.name
                              : '-'
                          }}</span
                        >
                      </div>
                      <div
                        class="grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-3 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer"
                        v-if="areCostCentersLoading"
                      >
                        <Spinner size="12px" />
                      </div>
                      <div v-else>
                        <div
                          v-for="cost_center in cost_centers"
                          :key="cost_center.id"
                        >
                          <div
                            class="grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-1.5 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer"
                            @click="handleSelectCostCenter(cost_center)"
                            data-cy="company-cost-center-select-cost-center"
                          >
                            <CompanyImageIcon
                              size="xxs"
                              :is-circle="false"
                              :data="cost_center"
                            />
                            <span
                              class="truncate text-ellipsis"
                              :class="{
                                'text-s1-blue-100':
                                  currentUser.currentUser.cost_center.value &&
                                  cost_center.id ===
                                    currentUser.currentUser.cost_center.value
                                      .id,
                              }"
                              >{{ cost_center.name }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div v-if="!isAdmin" class="flex justify-center flex-none px-8">
            <div class="self-stretch h-0.5 bg-default-bg w-full"></div>
          </div>
          <!-- menu & team -->
          <div
            class="flex flex-col flex-1 px-4 h-5/6"
            :class="{ 'justify-center': isCollapsed }"
          >
            <!-- menu -->
            <div
              class="h-5/12 mb-6 px-0.5 flex flex-col justify-center"
              :class="{
                'mt-6': !isAdmin,
              }"
              data-cy="menu"
            >
              <div
                class="text-10px font-medium mb-2 transition-all duration-300"
                :class="{ 'px-3': !isCollapsed, 'px-2.5': isCollapsed }"
              >
                {{ $t('header.menu.title') }}
              </div>

              <div
                v-for="(action, index) in currentUser.settings.navbar.menu"
                :key="index"
                :class="{
                  'w-12': isCollapsed,
                  'w-24': isCollapsed && action.isHovered,
                }"
                ref="menuItem"
              >
                <div
                  v-if="action.visible"
                  @mouseover="handleMouseOver(action, index)"
                  @mouseleave="handleMouseLeave(action)"
                  class="cursor-pointer"
                >
                  <div
                    class="flex p-3 justify-between items-center"
                    :class="{
                      'cursor-pointer rounded-lg':
                        isActive(action) || isSubItemActive(action),
                      'w-12 bg-fs-mid-light-blue ':
                        isCollapsed &&
                        (isActive(action) || isSubItemActive(action)),
                      'w-full bg-fs-mid-light-blue ':
                        !isCollapsed &&
                        (isActive(action) || isSubItemActive(action)),
                    }"
                    @click.stop="handleExpandMenu(action)"
                    :data-cy="`menu-${action.title}`"
                  >
                    <div class="flex w-full gap-3 items-center">
                      <div
                        class="flex items-center"
                        :class="{ 'cursor-pointer': !isCollapsed }"
                      >
                        <ActionIcon
                          :name="action.icon"
                          size="2xl"
                          :color="
                            action.isHovered ||
                            isActive(action) ||
                            isSubItemActive(action)
                              ? 's1-blue-100'
                              : 's1-gray-80'
                          "
                          v-tooltip="{
                            content: $t(`tooltips.${action.title}`),
                            placement: 'top-end',
                          }"
                        />
                      </div>
                      <transition name="fade">
                        <div
                          v-if="!isCollapsed"
                          :class="[
                            action.isHovered ||
                            isActive(action) ||
                            isSubItemActive(action)
                              ? 'text-s1-blue-100'
                              : 'text-s1-gray-80',
                            'cursor-pointer line-clamp-1 text-sm font-medium',
                          ]"
                          data-cy="menu-item"
                        >
                          {{ $t(`header.menu.${action.title}`) }}
                        </div>
                      </transition>
                    </div>
                    <div
                      :class="[
                        isActive(action) ? 'text-s1-blue-100' : 'text-inherit',
                        'cursor-pointer flex items-center',
                      ]"
                    >
                      <transition name="fade-slow">
                        <ActionIcon
                          v-if="!isCollapsed && action.canExpand"
                          :name="
                            action.isExpanded
                              ? 'nav_expand_less'
                              : 'nav_expand_more'
                          "
                          :color="
                            action.isHovered ||
                            isActive(action) ||
                            isSubItemActive(action)
                              ? 's1-blue-100'
                              : 's1-gray-80'
                          "
                        />
                      </transition>
                    </div>
                  </div>
                </div>
                <div class="ml-9">
                  <collapse-transition>
                    <div v-if="action.isExpanded === true && !isCollapsed">
                      <a
                        v-for="(item, index) in action.items.filter(
                          (item) => item.visible
                        )"
                        :key="index"
                        class="grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center p-3 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer"
                        :class="{
                          'text-s1-blue-100': isActive(item),
                        }"
                        @click.prevent="handleRouteChange(item.path)"
                      >
                        <a
                          v-if="item.visible"
                          :data-cy="`menu-item-${item.title}`"
                          class="text-sm font-medium w-full"
                        >
                          <router-link :to="item.path"
                            ><span
                              class="line-clamp-1"
                              :class="{
                                'text-s1-blue-100': isActive(item),
                              }"
                              >{{ $t(`header.menu.${item.title}`) }}</span
                            ></router-link
                          >
                        </a>
                      </a>
                    </div>
                  </collapse-transition>
                </div>
              </div>
            </div>

            <div class="flex justify-center flex-none px-4">
              <div class="self-stretch h-0.5 bg-default-bg w-full"></div>
            </div>
            <!-- team -->
            <div
              class="h-7/12 hide-scrollbar flex-1 my-6"
              :class="{ 'justify-center': isCollapsed }"
            >
              <div
                class="text-10px font-medium mb-2 transition-all duration-300"
                :class="{ 'px-3': !isCollapsed, 'px-2.5': isCollapsed }"
              >
                {{ $t('header.menu.team') }}
              </div>
              <div v-for="(user, index) in usersList" :key="`${index}-team`">
                <div class="flex items-center px-3 py-2 gap-4">
                  <div>
                    <Avatar
                      :user="user"
                      size="xxs"
                      statusClass="border-2 border-alabaster"
                      :with-status="user.status === 'active'"
                      tooltip-placement="top-end"
                    />
                  </div>
                  <transition name="fade">
                    <div
                      v-if="!isCollapsed"
                      class="text-sm font-medium text-s1-gray-100"
                    >
                      <span>{{ user.first_name }}</span
                      ><span class="px-1">{{ user.last_name }}</span>
                    </div>
                  </transition>
                </div>
              </div>
              <div v-if="users.team_list.length > 4" class="px-3 mt-4">
                <button
                  @click="isUsersFullList = !isUsersFullList"
                  class="w-8 h-5 rounded bg-s1-blue-100"
                >
                  <ActionIcon
                    :name="
                      isUsersFullList
                        ? 'actions_arrow_drop_up'
                        : 'actions_arrow_drop_down'
                    "
                    color="alabaster"
                  />
                </button>
              </div>
            </div>
          </div>
          <!-- collapse & theme -->
          <div
            class="h-auto w-full flex flex-col justify-end gap-4 inline-flex px-8"
          >
            <!-- collapse -->
            <div
              class="py-3 cursor-pointer hidden md:flex"
              @click="handleCollapse"
              @mouseover="hoverCollapseMenu = true"
              @mouseleave="hoverCollapseMenu = false"
              data-cy="collapse-button"
            >
              <ActionIcon
                :name="
                  isCollapsed ? 's1_icon_expandnav' : 's1_icon_collapsenav'
                "
                :color="hoverCollapseMenu ? 's1-blue-100' : 'medium-gray'"
              />
              <transition name="fade">
                <span
                  v-if="!isCollapsed"
                  class="pl-2"
                  :class="{ 'text-s1-blue-100': hoverCollapseMenu }"
                >
                  {{ $t('header.menu.collapse') }}
                </span>
              </transition>
            </div>
            <!-- theme -->
            <div class="flex justify-center items-center">
              <div
                class="bg-default-bg p-1"
                :class="{
                  'rounded-10': isCollapsed,
                  'rounded-xl': !isCollapsed,
                  'w-full ': isMobileView,
                }"
              >
                <transition name="fade-slow">
                  <div
                    v-if="isCollapsed"
                    @click="handleToggleAppearanceModeUpdate"
                    class="cursor-pointer h-6.5 w-6.5 p-1.25 bg-alabaster rounded-lg flex items-center justify-center"
                  >
                    <ActionIcon
                      :name="
                        isDarkMode ? 'Dark_rounded_24px' : 'Light_rounded_24px'
                      "
                      size="lg"
                    />
                  </div>
                  <div
                    v-else
                    class="w-full flex justify-center items-center h-6.5 gap-1"
                  >
                    <div
                      @click="handleAppearanceModeUpdate(false)"
                      class="flex items-center justify-center cursor-pointer w-1/2"
                    >
                      <div
                        class="flex justify-center py-1 px-6 w-full gap-2"
                        :class="{
                          'rounded-10 bg-alabaster transition-colors duration-300':
                            !isDarkMode,
                        }"
                      >
                        <ActionIcon name="Light_rounded_24px" />
                        <span>{{ $t('profile.headers.light') }}</span>
                      </div>
                    </div>
                    <div
                      @click="handleAppearanceModeUpdate(true)"
                      class="flex items-center justify-center cursor-pointer w-1/2"
                    >
                      <div
                        class="flex justify-center py-1 px-6 w-full gap-2"
                        :class="{
                          'rounded-10 bg-alabaster transition-colors duration-300':
                            isDarkMode,
                        }"
                      >
                        <ActionIcon name="Dark_rounded_24px" />
                        <span>{{ $t('profile.headers.dark') }}</span>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </transition>
    <div
      v-for="(action, index) in currentUser.settings.navbar.menu"
      :key="index"
    >
      <transition name="fade">
        <div
          v-show="isCollapsed && action.isHovered"
          @mouseover="handleMouseOver(action, index)"
          @mouseleave="handleMouseLeave(action)"
          :ref="action.title"
          class="-top-full fixed rounded-xl shadow-side-menu w-45.5 z-50"
        >
          <div
            :class="{
              'bg-alabaster': isCollapsed && action.isHovered,
            }"
            @mouseover="handleMouseOver(action, index)"
            @mouseleave="handleMouseLeave(action)"
            class="rounded-xl"
          >
            <a
              v-for="(item, index) in action.items.filter(
                (item) => item.visible
              )"
              :key="index"
              class="grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-3 px-4 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer"
              :class="{
                'text-s1-blue-100': isActive(item),
              }"
              @click.prevent="handleRouteChange(item.path)"
            >
              <a
                v-if="item.visible"
                :data-cy="`menu-item-${item.title}`"
                class="text-sm font-medium w-full"
              >
                <router-link :to="item.path"
                  ><span
                    class="line-clamp-1"
                    :class="{
                      'text-s1-blue-100': isActive(item),
                    }"
                    >{{ $t(`header.menu.${item.title}`) }}</span
                  ></router-link
                >
              </a>
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { createPopper } from '@popperjs/core'
import ActionIcon from '@/components/shared/atoms/action-icon'
import Avatar from '@/components/shared/atoms/avatar'
import ButtonClose from '@/components/shared/atoms/button-close'
import CompanyImageIcon from '@/components/companies/company-image-icon'
import detectScreenSize from '@/mixins/detect-screen-size'
import Logo from '@/components/shared/atoms/base-logo'
import MiniLogo from '@/components/shared/atoms/mini-logo'
import Spinner from '@/components/shared/atoms/spinner'

export default {
  components: {
    ActionIcon,
    Avatar,
    ButtonClose,
    CollapseTransition,
    CompanyImageIcon,
    Logo,
    MiniLogo,
    Spinner,
  },
  mixins: [detectScreenSize],
  data() {
    return {
      companies: [],
      cost_centers: [],
      hoverMenuItem: false,
      hoveredCompany: false,
      hoveredCompaniesModal: false,
      hoverCollapseMenu: false,
      isCompaniesModalOpened: false,
      currentCompanyHovered: null,
      areCompaniesLoading: false,
      areCostCentersLoading: false,
      isUsersFullList: false,
      currentTheme: null,
      popperCompanyInstance: null,
      popperMenuItemInstance: null,
    }
  },
  async created() {
    await this.loadTeamList({ internal: this.isAdmin })
    this.currentTheme = this.isDarkMode

    if (!this.currentUser.settings.navbar.menu) {
      this.currentUser.settings.navbar.menu = this.actions
    }
  },
  methods: {
    ...mapActions('currentUser', [
      'getAvailableCompanies',
      'getAvailableCostCenters',
      'updateProfile',
      'updateNavbarSettings',
      'updateNavbarMenuItems',
      'updatePreferences',
      'toggleDarkMode',
    ]),
    ...mapActions('users', ['loadTeamList']),
    async handleCompaniesList() {
      if (this.isCompaniesModalOpened) return

      this.areCompaniesLoading = true
      this.isCompaniesModalOpened = true
      const resp = await this.getAvailableCompanies()
      this.companies = resp.companies
      this.areCompaniesLoading = false
    },
    async handleSelectCompany(company) {
      this.cost_centers = []
      this.areCostCentersLoading = true
      await this.updatePreferences({
        company_id: company.id,
        cost_center_id: null,
      })
      this.cost_centers = company.cost_centers
      this.areCostCentersLoading = false
      this.isCompaniesModalOpened = false
    },
    async handleSelectCostCenter(cost_center) {
      await this.updatePreferences({
        cost_center_id: cost_center.id,
      })
      if (this.currentUser.currentUser.cost_center.value) {
        location.reload()
      }
      this.isCompaniesModalOpened = false
    },
    async handleCompanyHovered() {
      this.hoveredCompany = true
      if (this.cost_centers.length > 0) return

      if (!this.popperCompanyInstance) {
        this.popperCompanyInstance = createPopper(
          this.$refs.companyMenu,
          this.$refs.companyDropdown,
          {
            placement: 'right-start',
            strategy: 'fixed',
          }
        )
      }

      if (this.selectedCompanyId) {
        const resp = await this.getAvailableCostCenters({
          company_id: this.selectedCompanyId,
        })
        this.cost_centers = resp.data.cost_centers
      } else {
        await this.handleCompaniesList()
      }
    },
    handleMouseOver(action, index) {
      action.isHovered = action.canExpand

      if (!this.popperMenuItemInstance) {
        this.popperMenuItemInstance = createPopper(
          this.$refs.menuItem[index],
          this.$refs[action.title][0],
          {
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [10, -40],
                },
              },
            ],
            placement: 'right-start',
            strategy: 'fixed',
          }
        )
      }
    },
    handleMouseLeave(action) {
      action.isHovered = false
      if (this.popperMenuItemInstance) {
        this.popperMenuItemInstance.destroy()
      }
      this.popperMenuItemInstance = null
    },
    handleCollapse() {
      if (this.isDesktopView) {
        this.updateNavbarSettings({
          isCollapsed: this.isCollapsed,
        })
      } else {
        this.updateNavbarSettings({
          isCollapsed: !this.isCollapsed,
        })
      }
    },
    handleExpandMenu(action) {
      if (action.items.length === 0) {
        this.handleRouteChange(action.path)
      }

      if (this.isCollapsed) return

      if (action.canExpand) {
        this.updateNavbarMenuItems({
          ...action,
          isExpanded: !action.isExpanded,
        })
      }
    },
    async handleAppearanceModeUpdate(value) {
      if ((value && this.isDarkMode) || (!value && !this.isDarkMode)) return

      this.toggleDarkMode()
      await this.updateProfile({
        information: {
          first_name: this.currentUser.currentUser.first_name,
          last_name: this.currentUser.currentUser.last_name,
          initials: this.currentUser.currentUser.initials,
        },
        security: {
          current_password: '',
          password: '',
          password_confirmation: '',
        },
        settings: {
          dark_mode: value,
        },
      })
    },
    async handleToggleAppearanceModeUpdate() {
      this.currentTheme = !this.isDarkMode

      this.toggleDarkMode()
      await this.updateProfile({
        information: {
          first_name: this.currentUser.currentUser.first_name,
          last_name: this.currentUser.currentUser.last_name,
          initials: this.currentUser.currentUser.initials,
        },
        security: {
          current_password: '',
          password: '',
          password_confirmation: '',
        },
        settings: {
          dark_mode: this.currentTheme,
        },
      })
    },
    handleRouteChange(path) {
      if (path === this.$route.path) {
        return
      }

      this.$router.push(path)
    },
  },
  computed: {
    ...mapState(['currentUser', 'users']),
    ...mapGetters('currentUser', [
      'currentCompanyName',
      'currentCostCenterName',
      'isDarkMode',
      'isAdmin',
    ]),
    actions() {
      return [
        {
          icon: 'side-nav_dashboard',
          title: 'dashboard',
          path: '/dashboard',
          canExpand: false,
          isHovered: false,
          visible: this.$can('manage', 'dashboard'),
          items: [],
        },
        {
          icon: 'side-nav_accounts',
          title: 'accounts',
          path: '/accounts',
          canExpand: false,
          isHovered: false,
          visible: this.$can('manage', 'accounts'),
          items: [],
        },
        {
          icon: 'side-nav_items',
          title: 'goods',
          path: '',
          canExpand: true,
          isExpanded: false,
          isHovered: false,
          visible: true,
          items: [
            {
              title: 'items',
              path: '/items',
              visible: this.$can('manage', 'items') && !this.isAdmin,
            },
            {
              title: 'suppliers',
              path: '/supplier-tenants',
              visible:
                !this.isAdmin &&
                (this.$can('manage', 'suppliers') ||
                  this.$can('view', 'suppliers')),
            },
            {
              title: 'suppliers',
              path: '/settings/suppliers',
              visible: this.isAdmin,
            },
            {
              title: 'imports',
              path: '/import-history',
              visible: !this.isAdmin,
            },
            {
              title: 'replacement_groups',
              path: '/replacement-groups',
              visible: !this.isAdmin,
            },
            {
              title: 'expiring_prices',
              path: '/expiring-prices',
              visible:
                this.$can('manage', 'expired_price_item') && !this.isAdmin,
            },
          ],
        },
        {
          icon: 's1_icon_orders',
          title: 'ordering',
          path: '',
          canExpand: true,
          isExpanded: false,
          isHovered: false,
          items: [
            {
              title: 'cart_templates',
              path: '/cart-templates',
              visible: this.$can('manage', 'cart_templates') && !this.isAdmin,
            },
            {
              title: 'orders',
              path: '/orders',
              visible: this.$can('manage', 'orders') && !this.isAdmin,
            },
            {
              title: 'deliveries',
              path: '/deliveries',
              visible: !this.isAdmin,
            },
            {
              title: 'open_payments',
              path: '/open-payments',
              visible: !this.isAdmin,
            },
          ],
          visible: !this.isAdmin,
        },
        {
          icon: 's1_icon_masterdata',
          title: 'data',
          path: '',
          canExpand: true,
          isExpanded: false,
          isHovered: false,
          visible: true,
          items: [
            {
              title: 'product_groups',
              path: '/settings/product-groups',
              visible: true,
            },
            {
              title: 'allergens',
              path: '/settings/allergens',
              visible: true,
            },
            {
              title: 'units',
              path: '/settings/units',
              visible: true,
            },
            {
              title: 'vat_rates',
              path: '/settings/tax-rates',
              visible: true,
            },
            {
              title: 'additives',
              path: '/settings/additives',
              visible: true,
            },
          ],
        },
        {
          icon: 'side-nav_settings',
          title: 'account_and_settings',
          path: '',
          canExpand: true,
          isExpanded: false,
          isHovered: false,
          visible: !this.isAdmin,
          items: [
            {
              title: 'profile',
              path: '/profile',
              visible: true,
            },
            {
              title: 'account_members',
              path: '/members',
              visible: true,
            },
            {
              title: 'companies',
              path: '/companies',
              visible: true,
            },
          ],
        },
        {
          icon: 'side-nav_settings',
          title: 'internal_users',
          path: '/internal_users',
          canExpand: false,
          isHovered: false,
          visible: this.isAdmin,
          items: [],
        },
      ]
    },
    isActive() {
      return (item) =>
        item.path === this.$route.path ||
        item.path === this.$route.meta.subPathOf
    },
    isCollapsed() {
      if (this.isDesktopView) {
        return !(
          this.currentUser.settings.navbar.isCollapsed && this.isDesktopView
        )
      }

      return this.currentUser.settings.navbar.isCollapsed && !this.isMobileView
    },
    isSubItemActive() {
      return (action) => action.items.some((item) => this.isActive(item))
    },
    usersList() {
      return this.isUsersFullList
        ? this.users.team_list
        : this.users.team_list.slice(0, 4)
    },
    pageUrl: () => (url) => {
      return process.env.VUE_APP_API_URL + url
    },
    selectedCompanyId() {
      const companyValue = this.currentUser.currentUser.company.value
      return companyValue ? companyValue.id : null
    },
  },
}
</script>

<style>
.hide-scrollbar {
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
