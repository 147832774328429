var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-navigation-wrapper"},[_c('transition',{attrs:{"name":_vm.isXsMobileView ? 'scale-modal' : 'fade'}},[_c('aside',{staticClass:"fixed overflow-y-auto overflow-x-hidden z-50 md:z-40 bg-alabaster flex flex-col h-screen transition-all duration-300 pb-6 border-r border-default-bg top-0",class:{
        'w-21.7': _vm.isCollapsed,
        'w-full md:w-284px': !_vm.isCollapsed,
      }},[_c('div',{staticClass:"h-20 flex flex-none items-center justify-between px-4 py-3"},[_c('div',{staticClass:"md:hidden"},[_c('ButtonClose',{staticClass:"flex-none",on:{"click":function($event){return _vm.$emit('close-menu')}}})],1),_c('div',{staticClass:"pl-2 xl:hidden"},[(_vm.isCollapsed)?_c('MiniLogo',{staticClass:"text-medium-gray cursor-pointer w-8",attrs:{"type":"dark"},on:{"click":function($event){return _vm.$router.push('/')}}}):_c('Logo',{staticClass:"xl:flex-1 w-31 text-medium-gray cursor-pointer",attrs:{"type":"dark"},on:{"click":function($event){return _vm.$router.push('/')}}})],1)]),_c('div',{staticClass:"flex flex-col flex-1 justify-between pt-6"},[(!_vm.isAdmin)?_c('div',{ref:"companyMenu",staticClass:"px-4",attrs:{"data-cy":"company-cost-center"},on:{"mouseover":_vm.handleCompanyHovered,"mouseleave":function($event){_vm.hoveredCompany = false}}},[_c('div',{staticClass:"flex h-14 py-2 mb-6 flex-col bg-default-bg rounded-xl justify-center items-center relative",class:{ 'w-14': _vm.isCollapsed, 'px-2': !_vm.isMobileView },on:{"mouseover":_vm.handleCompanyHovered}},[_c('div',{staticClass:"absolute z-40 w-full h-full"}),_c('div',{staticClass:"flex self-stretch justify-start items-center gap-3"},[_c('div',{staticClass:"w-10 h-10 rounded-md justify-center items-center flex relative"},[_c('CompanyImageIcon',{class:{ 'px-2': _vm.isMobileView },attrs:{"size":"md","is-circle":false,"data":_vm.currentUser.currentUser.company.value}}),(
                    _vm.isCollapsed && _vm.currentUser.currentUser.cost_center.value
                  )?_c('div',{staticClass:"absolute w-6 h-6 -bottom-2.5 -right-1 shadow-md"},[_c('CompanyImageIcon',{attrs:{"size":"xxs","is-circle":false,"data":_vm.currentUser.currentUser.cost_center.value}})],1):_vm._e()],1),_c('div',{class:{ hidden: _vm.isCollapsed }},[_c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('div',{staticClass:"truncate text-ellipsis text-xs text-s1-gray-100 font-medium"},[_vm._v(" "+_vm._s(_vm.currentCompanyName || '-')+" ")]),_c('div',{staticClass:"truncate text-ellipsis text-xs text-s1-gray-80 font-normal"},[_vm._v(" "+_vm._s(_vm.currentCostCenterName || '-')+" ")])])])],1),_c('transition',{attrs:{"name":_vm.isMobileView ? 'scale-modal' : 'fade'}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoveredCompany),expression:"hoveredCompany"}],ref:"companyDropdown",staticClass:"bg-alabaster h-auto rounded-lg py-3 shadow-side-menu",class:{
                    'left-17.25': _vm.isCollapsed && !_vm.isMobileView,
                    'left-67': !_vm.isCollapsed && !_vm.isMobileView,
                    'w-64 top-0 px-3': !_vm.isMobileView,
                    'w-full top-16 z-60': _vm.isMobileView,
                  },on:{"mouseover":_vm.handleCompanyHovered,"mouseleave":function($event){_vm.hoveredCompany = false}}},[(_vm.isCompaniesModalOpened)?_c('div',[(_vm.areCompaniesLoading)?_c('div',{staticClass:"flex justify-center items-center h-20 w-full"},[_c('Spinner',{attrs:{"size":"12px"}})],1):_c('div',_vm._l((_vm.companies),function(company){return _c('div',{key:company.id},[_c('div',{staticClass:"grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-between items-center py-1.5 gap-4 flex w-full cursor-pointer",attrs:{"data-cy":"company-cost-center-select-company"},on:{"click":function($event){return _vm.handleSelectCompany(company)},"mouseover":function($event){_vm.currentCompanyHovered = company.id},"mouseleave":function($event){_vm.currentCompanyHovered = null}}},[_c('div',{staticClass:"flex flex-row items-center gap-2"},[_c('CompanyImageIcon',{attrs:{"size":"sm","is-circle":false,"data":company}}),_c('span',{staticClass:"truncate text-ellipsis",class:{
                                'text-s1-blue-100':
                                  _vm.currentCompanyHovered === company.id,
                              }},[_vm._v(_vm._s(company.name))])],1),_c('ActionIcon',{attrs:{"name":"nav_navigate_next","size":"2xl","color":_vm.currentCompanyHovered === company.id
                                ? 's1-blue-100'
                                : 's1-gray-80'}})],1)])}),0)]):_c('div',[_c('div',{staticClass:"grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-1.5 gap-4 flex w-full cursor-pointer",on:{"click":_vm.handleCompaniesList,"mouseover":function($event){_vm.currentCompanyHovered = _vm.selectedCompanyId},"mouseleave":function($event){_vm.currentCompanyHovered = null}}},[_c('ActionIcon',{attrs:{"name":"nav_navigate_before","size":"2xl","color":_vm.currentCompanyHovered ? 's1-blue-100' : 's1-gray-80'}}),_c('span',{class:{
                          'text-s1-blue-100':
                            _vm.currentCompanyHovered === _vm.selectedCompanyId,
                        }},[_vm._v(_vm._s(_vm.selectedCompanyId ? _vm.currentUser.currentUser.company.value.name : '-'))])],1),(_vm.areCostCentersLoading)?_c('div',{staticClass:"grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-3 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer"},[_c('Spinner',{attrs:{"size":"12px"}})],1):_c('div',_vm._l((_vm.cost_centers),function(cost_center){return _c('div',{key:cost_center.id},[_c('div',{staticClass:"grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-1.5 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer",attrs:{"data-cy":"company-cost-center-select-cost-center"},on:{"click":function($event){return _vm.handleSelectCostCenter(cost_center)}}},[_c('CompanyImageIcon',{attrs:{"size":"xxs","is-circle":false,"data":cost_center}}),_c('span',{staticClass:"truncate text-ellipsis",class:{
                              'text-s1-blue-100':
                                _vm.currentUser.currentUser.cost_center.value &&
                                cost_center.id ===
                                  _vm.currentUser.currentUser.cost_center.value
                                    .id,
                            }},[_vm._v(_vm._s(cost_center.name))])],1)])}),0)])])])],1)])]):_vm._e(),(!_vm.isAdmin)?_c('div',{staticClass:"flex justify-center flex-none px-8"},[_c('div',{staticClass:"self-stretch h-0.5 bg-default-bg w-full"})]):_vm._e(),_c('div',{staticClass:"flex flex-col flex-1 px-4 h-5/6",class:{ 'justify-center': _vm.isCollapsed }},[_c('div',{staticClass:"h-5/12 mb-6 px-0.5 flex flex-col justify-center",class:{
              'mt-6': !_vm.isAdmin,
            },attrs:{"data-cy":"menu"}},[_c('div',{staticClass:"text-10px font-medium mb-2 transition-all duration-300",class:{ 'px-3': !_vm.isCollapsed, 'px-2.5': _vm.isCollapsed }},[_vm._v(" "+_vm._s(_vm.$t('header.menu.title'))+" ")]),_vm._l((_vm.currentUser.settings.navbar.menu),function(action,index){return _c('div',{key:index,ref:"menuItem",refInFor:true,class:{
                'w-12': _vm.isCollapsed,
                'w-24': _vm.isCollapsed && action.isHovered,
              }},[(action.visible)?_c('div',{staticClass:"cursor-pointer",on:{"mouseover":function($event){return _vm.handleMouseOver(action, index)},"mouseleave":function($event){return _vm.handleMouseLeave(action)}}},[_c('div',{staticClass:"flex p-3 justify-between items-center",class:{
                    'cursor-pointer rounded-lg':
                      _vm.isActive(action) || _vm.isSubItemActive(action),
                    'w-12 bg-fs-mid-light-blue ':
                      _vm.isCollapsed &&
                      (_vm.isActive(action) || _vm.isSubItemActive(action)),
                    'w-full bg-fs-mid-light-blue ':
                      !_vm.isCollapsed &&
                      (_vm.isActive(action) || _vm.isSubItemActive(action)),
                  },attrs:{"data-cy":`menu-${action.title}`},on:{"click":function($event){$event.stopPropagation();return _vm.handleExpandMenu(action)}}},[_c('div',{staticClass:"flex w-full gap-3 items-center"},[_c('div',{staticClass:"flex items-center",class:{ 'cursor-pointer': !_vm.isCollapsed }},[_c('ActionIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t(`tooltips.${action.title}`),
                          placement: 'top-end',
                        }),expression:"{\n                          content: $t(`tooltips.${action.title}`),\n                          placement: 'top-end',\n                        }"}],attrs:{"name":action.icon,"size":"2xl","color":action.isHovered ||
                          _vm.isActive(action) ||
                          _vm.isSubItemActive(action)
                            ? 's1-blue-100'
                            : 's1-gray-80'}})],1),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isCollapsed)?_c('div',{class:[
                          action.isHovered ||
                          _vm.isActive(action) ||
                          _vm.isSubItemActive(action)
                            ? 'text-s1-blue-100'
                            : 'text-s1-gray-80',
                          'cursor-pointer line-clamp-1 text-sm font-medium',
                        ],attrs:{"data-cy":"menu-item"}},[_vm._v(" "+_vm._s(_vm.$t(`header.menu.${action.title}`))+" ")]):_vm._e()])],1),_c('div',{class:[
                      _vm.isActive(action) ? 'text-s1-blue-100' : 'text-inherit',
                      'cursor-pointer flex items-center',
                    ]},[_c('transition',{attrs:{"name":"fade-slow"}},[(!_vm.isCollapsed && action.canExpand)?_c('ActionIcon',{attrs:{"name":action.isExpanded
                            ? 'nav_expand_less'
                            : 'nav_expand_more',"color":action.isHovered ||
                          _vm.isActive(action) ||
                          _vm.isSubItemActive(action)
                            ? 's1-blue-100'
                            : 's1-gray-80'}}):_vm._e()],1)],1)])]):_vm._e(),_c('div',{staticClass:"ml-9"},[_c('collapse-transition',[(action.isExpanded === true && !_vm.isCollapsed)?_c('div',_vm._l((action.items.filter(
                        (item) => item.visible
                      )),function(item,index){return _c('a',{key:index,staticClass:"grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center p-3 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer",class:{
                        'text-s1-blue-100': _vm.isActive(item),
                      },on:{"click":function($event){$event.preventDefault();return _vm.handleRouteChange(item.path)}}},[(item.visible)?_c('a',{staticClass:"text-sm font-medium w-full",attrs:{"data-cy":`menu-item-${item.title}`}},[_c('router-link',{attrs:{"to":item.path}},[_c('span',{staticClass:"line-clamp-1",class:{
                              'text-s1-blue-100': _vm.isActive(item),
                            }},[_vm._v(_vm._s(_vm.$t(`header.menu.${item.title}`)))])])],1):_vm._e()])}),0):_vm._e()])],1)])})],2),_c('div',{staticClass:"flex justify-center flex-none px-4"},[_c('div',{staticClass:"self-stretch h-0.5 bg-default-bg w-full"})]),_c('div',{staticClass:"h-7/12 hide-scrollbar flex-1 my-6",class:{ 'justify-center': _vm.isCollapsed }},[_c('div',{staticClass:"text-10px font-medium mb-2 transition-all duration-300",class:{ 'px-3': !_vm.isCollapsed, 'px-2.5': _vm.isCollapsed }},[_vm._v(" "+_vm._s(_vm.$t('header.menu.team'))+" ")]),_vm._l((_vm.usersList),function(user,index){return _c('div',{key:`${index}-team`},[_c('div',{staticClass:"flex items-center px-3 py-2 gap-4"},[_c('div',[_c('Avatar',{attrs:{"user":user,"size":"xxs","statusClass":"border-2 border-alabaster","with-status":user.status === 'active',"tooltip-placement":"top-end"}})],1),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isCollapsed)?_c('div',{staticClass:"text-sm font-medium text-s1-gray-100"},[_c('span',[_vm._v(_vm._s(user.first_name))]),_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(user.last_name))])]):_vm._e()])],1)])}),(_vm.users.team_list.length > 4)?_c('div',{staticClass:"px-3 mt-4"},[_c('button',{staticClass:"w-8 h-5 rounded bg-s1-blue-100",on:{"click":function($event){_vm.isUsersFullList = !_vm.isUsersFullList}}},[_c('ActionIcon',{attrs:{"name":_vm.isUsersFullList
                      ? 'actions_arrow_drop_up'
                      : 'actions_arrow_drop_down',"color":"alabaster"}})],1)]):_vm._e()],2)]),_c('div',{staticClass:"h-auto w-full flex flex-col justify-end gap-4 inline-flex px-8"},[_c('div',{staticClass:"py-3 cursor-pointer hidden md:flex",attrs:{"data-cy":"collapse-button"},on:{"click":_vm.handleCollapse,"mouseover":function($event){_vm.hoverCollapseMenu = true},"mouseleave":function($event){_vm.hoverCollapseMenu = false}}},[_c('ActionIcon',{attrs:{"name":_vm.isCollapsed ? 's1_icon_expandnav' : 's1_icon_collapsenav',"color":_vm.hoverCollapseMenu ? 's1-blue-100' : 'medium-gray'}}),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isCollapsed)?_c('span',{staticClass:"pl-2",class:{ 'text-s1-blue-100': _vm.hoverCollapseMenu }},[_vm._v(" "+_vm._s(_vm.$t('header.menu.collapse'))+" ")]):_vm._e()])],1),_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"bg-default-bg p-1",class:{
                'rounded-10': _vm.isCollapsed,
                'rounded-xl': !_vm.isCollapsed,
                'w-full ': _vm.isMobileView,
              }},[_c('transition',{attrs:{"name":"fade-slow"}},[(_vm.isCollapsed)?_c('div',{staticClass:"cursor-pointer h-6.5 w-6.5 p-1.25 bg-alabaster rounded-lg flex items-center justify-center",on:{"click":_vm.handleToggleAppearanceModeUpdate}},[_c('ActionIcon',{attrs:{"name":_vm.isDarkMode ? 'Dark_rounded_24px' : 'Light_rounded_24px',"size":"lg"}})],1):_c('div',{staticClass:"w-full flex justify-center items-center h-6.5 gap-1"},[_c('div',{staticClass:"flex items-center justify-center cursor-pointer w-1/2",on:{"click":function($event){return _vm.handleAppearanceModeUpdate(false)}}},[_c('div',{staticClass:"flex justify-center py-1 px-6 w-full gap-2",class:{
                        'rounded-10 bg-alabaster transition-colors duration-300':
                          !_vm.isDarkMode,
                      }},[_c('ActionIcon',{attrs:{"name":"Light_rounded_24px"}}),_c('span',[_vm._v(_vm._s(_vm.$t('profile.headers.light')))])],1)]),_c('div',{staticClass:"flex items-center justify-center cursor-pointer w-1/2",on:{"click":function($event){return _vm.handleAppearanceModeUpdate(true)}}},[_c('div',{staticClass:"flex justify-center py-1 px-6 w-full gap-2",class:{
                        'rounded-10 bg-alabaster transition-colors duration-300':
                          _vm.isDarkMode,
                      }},[_c('ActionIcon',{attrs:{"name":"Dark_rounded_24px"}}),_c('span',[_vm._v(_vm._s(_vm.$t('profile.headers.dark')))])],1)])])])],1)])])])])]),_vm._l((_vm.currentUser.settings.navbar.menu),function(action,index){return _c('div',{key:index},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollapsed && action.isHovered),expression:"isCollapsed && action.isHovered"}],ref:action.title,refInFor:true,staticClass:"-top-full fixed rounded-xl shadow-side-menu w-45.5 z-50",on:{"mouseover":function($event){return _vm.handleMouseOver(action, index)},"mouseleave":function($event){return _vm.handleMouseLeave(action)}}},[_c('div',{staticClass:"rounded-xl",class:{
            'bg-alabaster': _vm.isCollapsed && action.isHovered,
          },on:{"mouseover":function($event){return _vm.handleMouseOver(action, index)},"mouseleave":function($event){return _vm.handleMouseLeave(action)}}},_vm._l((action.items.filter(
              (item) => item.visible
            )),function(item,index){return _c('a',{key:index,staticClass:"grow shrink basis-0 self-stretch rounded-lg text-s1-gray-80 justify-start items-center py-3 px-4 gap-4 flex w-full hover:text-s1-blue-100 cursor-pointer",class:{
              'text-s1-blue-100': _vm.isActive(item),
            },on:{"click":function($event){$event.preventDefault();return _vm.handleRouteChange(item.path)}}},[(item.visible)?_c('a',{staticClass:"text-sm font-medium w-full",attrs:{"data-cy":`menu-item-${item.title}`}},[_c('router-link',{attrs:{"to":item.path}},[_c('span',{staticClass:"line-clamp-1",class:{
                    'text-s1-blue-100': _vm.isActive(item),
                  }},[_vm._v(_vm._s(_vm.$t(`header.menu.${item.title}`)))])])],1):_vm._e()])}),0)])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }